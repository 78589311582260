import React from "react";

export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;

  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}

class FormProstranstviHeader extends React.Component<AppProps> {
  private componentName = "form_odpad_header";

  constructor(props: AppProps) {
    super(props);
  }

  public componentDidMount = async () => {
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.props.data
    );
  };

  public render() {
    return (
      <div className="row mb-4">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>
                Přiznání k místnímu poplatku za provoz systému shromažďování, sběru, přepravy, třídění, využívání a
                odstraňování komunálních odpadů
              </strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FormProstranstviHeader;
