import React, { useEffect, useState } from "react";

interface iCaptcha {
  children: React.ReactNode;
}

const TRY_COUNT = 5;
const WAIT_TIME = 30;
const CAPTCHA_LEN = 6;

const generateCaptchaPhrase = () => {
  const charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"; //@!#$%^&*
  const lengthOtp = CAPTCHA_LEN;
  const captcha: string[] = [];
  for (let i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    const index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) === -1) captcha.push(charsArray[index]);
    else i--;
  }
  return captcha.join("");
};

const createCaptchaImg = (captchaPhrase: string) => {
  const canv = document.createElement("canvas");
  canv.id = "captcha";
  canv.width = 20 * CAPTCHA_LEN;
  canv.height = 50;
  const ctx = canv.getContext("2d")!;
  ctx.font = "25px Georgia";

  ctx.strokeText(captchaPhrase, 0, 30);
  return canv.toDataURL();
};

const generateCaptcha = () => {
  const phrase = generateCaptchaPhrase();
  return {
    phrase: hashString(phrase),
    img: createCaptchaImg(phrase)
  };
};

const hashString = (s: string) => s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

const Captcha = ({ children }: iCaptcha) => {
  const [captcha, setCaptcha] = useState(generateCaptcha());

  const [timer, setTimer] = useState(0);
  const [tryCount, setTryCount] = useState(TRY_COUNT);

  const [inputVal, setInputVal] = useState("");
  const [valid, setValid] = useState<boolean | undefined>(undefined);

  const handleReload = () => {
    setCaptcha(generateCaptcha());
  };

  const validateCaptcha = (e: any) => {
    e.preventDefault();
    if (hashString(inputVal) === captcha.phrase) {
      setValid(true);
    } else {
      setValid(false);
      setInputVal("");
      handleReload();
      setTryCount(tryCount - 1);
    }
  };

  useEffect(() => {
    if (tryCount < 1) {
      startTimer();
      setTryCount(TRY_COUNT);
      setValid(undefined);
    }
  }, [tryCount]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const startTimer = () => {
    setTimer(WAIT_TIME);
  };

  if (valid) {
    return <>{children}</>;
  }

  if (timer > 0) {
    return (
      <div className="text-danger font-weight-bold my-3">
        Více než {TRY_COUNT} neplatných pokusů. Čekejte: {timer}s
      </div>
    );
  }

  return (
    <div className="my-3 text-center">
      <h4>Opište text z obrázku</h4>
      {valid === false && <div className="text-danger font-weight-bold">Chyba. Text není stejný.</div>}
      <img alt="Captcha img" src={captcha.img} />
      <small style={{ cursor: "pointer", textDecoration: "underline" }} onClick={handleReload}>
        Jiný obrázek
      </small>
      <br />
      <input
        style={{ width: 90, padding: 7, fontSize: 16, display: "inline-block" }}
        value={inputVal}
        onChange={e => setInputVal(e.target.value)}
      />
      <button className="btn btn-secondary mx-2" onClick={validateCaptcha}>
        Ověřit
      </button>
    </div>
  );
};

export default Captcha;
