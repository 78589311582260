import React from "react";

// Import regular stylesheet

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons/faMoneyBillAlt";
import { Input } from "reactstrap";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
}

class FormProstranstviPriznani extends React.Component<AppProps> {
  private componentName = "form_prostranstvi_priznani";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faMoneyBillAlt} className={"mr-2"} /> Podávám přiznání k platbě místního poplatku za
          užívání veřejného prostranství na místo a účel užívání
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_dne" className="col-lg-3 col-form-label text-lg-right">
            Dohodnuto dne
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_dne"
                name={"prostranstvi_priznani_dne"}
                value={this.props.data.prostranstvi_priznani_dne}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_m2" className="col-lg-3 col-form-label text-lg-right">
            Počet m<sup>2</sup>
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="prostranstvi_priznani_m2"
                name={"prostranstvi_priznani_m2"}
                value={this.props.data.prostranstvi_priznani_m2}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_povinnost" className="col-lg-3 col-form-label text-lg-right">
            Poplatková povinnost od – do
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_povinnost"
                name={"prostranstvi_priznani_povinnost"}
                value={this.props.data.prostranstvi_priznani_povinnost}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_povinnost_dny" className="col-lg-3 col-form-label text-lg-right">
            Počet dnů
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="prostranstvi_priznani_povinnost_dny"
                name={"prostranstvi_priznani_povinnost_dny"}
                value={this.props.data.prostranstvi_priznani_povinnost_dny}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_sazba" className="col-lg-3 col-form-label text-lg-right">
            Sazba poplatku za 1 den (dle OZV, čl. 15)
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_sazba"
                name={"prostranstvi_priznani_sazba"}
                value={this.props.data.prostranstvi_priznani_sazba}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="prostranstvi_priznani_poplatek" className="col-lg-3 col-form-label text-lg-right">
            Poplatek celkem
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="prostranstvi_priznani_poplatek"
                name={"prostranstvi_priznani_poplatek"}
                value={this.props.data.prostranstvi_priznani_poplatek}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default FormProstranstviPriznani;
