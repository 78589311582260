import React from "react";

class FormKapacitaHeader extends React.Component {
  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Přiznání místního poplatku z ubytovací kapacity</strong>
            </h2>
            Dle ustanovení § 14a zákona č. 595/1990 Sb., o místních poplatcích, ve znění pozdějších předpisů, jste
            povinen ohlásit tyto údaje:
          </div>
        </div>
      </div>
    );
  }
}

export default FormKapacitaHeader;
