import React from "react";
import HeaderProps from "./HeaderProps";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";

export default class FormProstrediHeader extends React.Component<HeaderProps> {
  private componentName = "form_prostredi_header";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: HeaderProps) {
    super(props);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.props.data
    );
  };

  public render() {
    return (
      <div className="row mb-4">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Žádost o souhrnné stanovisko odboru životního prostředí</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
