import FooterProps from "./FooterProps";
import * as React from "react";
import config from "../../../../config/config";

const FooterPdf = (props: FooterProps) => {
  const { files, footer } = props;
  return (
    <React.Fragment>
      {files.length ? (
        <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
          Soubory
        </div>
      ) : (
        ""
      )}
      {files.map((file: any, index: any) => {
        return (
          <div key={index}>
            <a href={`${config.API}/${file.file}`}>{file.file_name}</a>
            <br />
          </div>
        );
      })}
      <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
        Podávající
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Jméno:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{footer.Podavajici.podavajici_jmeno}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Příjmení:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{footer.Podavajici.podavajici_prijmeni}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Telefon:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{footer.Podavajici.podavajici_telefon}</span>
        </div>
      </div>
      <div className={"row"}>
        <div className={"label-title col-3"}>Email:</div>
        <div className={"col-9"}>
          <span className={"underline-dotted"}>{footer.Podavajici.podavajici_email}</span>
        </div>
      </div>
      <div className={"row d-flex justify-content-center"} style={{ width: "100%", position: "fixed", bottom: 0 }}>
        <small>
          <i>e-forms, (c) QCM, s.r.o. </i>
        </small>
      </div>
    </React.Fragment>
  );
};

export default FooterPdf;
