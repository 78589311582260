import React from 'react'

const DrevinyPopis = ({ data }) => (
    <React.Fragment>
        <div className={'section-title mt-5'} style={{textDecoration: 'underline'}}>Popis kácení</div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Katastrální území
                :</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.katastralni_uzemi}</span>
            </div>
        </div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Parcela:</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.parcela}</span>
            </div>
        </div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Stručný popis umístění:</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.strucny_popis}</span>
            </div>
        </div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Druh dřeviny:</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.druh_dreviny}</span></div>
        </div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Počet kácených dřevin:</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.pocet_kacenych_drevin}</span></div>
        </div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Výměra kácené plochy:</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.vymera_kacene_plochy}</span></div>
        </div>
        <div className={'row'}>
            <div className={'label-title col-3'}>Obvod kmene ve výšce 130cm nad zemí:</div>
            <div className={'col-9'}><span className={'underline-dotted'}>{data.odvod_kmene}</span></div>
        </div>
    </React.Fragment>
);

export default DrevinyPopis