import React from "react";
import StoreLocal from "../../../libs/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import InputTextarea from "../../InputTextarea";
import { PopisState } from "../form_akce_les_popis/PopisState";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}

export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;

  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}

export interface AppState {
  informace_specifikace: string;
}

class InformaceZadatel extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  private componentName = "form_informace_specifikace";

  constructor(props: AppProps) {
    super(props);
    this.state = {
      informace_specifikace: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  public onChange = async (e: React.FormEvent<HTMLTextAreaElement>) => {
    this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof AppState]: AppState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faQuestionCircle} className={"mr-2"} /> Specifikace žádosti o poskytnutí informace
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_specifikace" className="col-lg-3 col-form-label text-lg-right">
            Specifikace žádosti
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="informace_specifikace"
                name={"informace_specifikace"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_specifikace}
                placeholder={"Specifikujte předmět žádosti"}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default InformaceZadatel;
