import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
  children?: ReactNode;
}
const PodatelnaSpecifikace: React.FunctionComponent = ({ data }: AppProps) => (
  <fieldset className={"mt-5"}>
    <legend>
      <FontAwesomeIcon icon={faQuestionCircle} className={"mr-2"} /> Specifikace podání
    </legend>

    <div className={"form-group align-items-center row mt-4"}>
      <label htmlFor="podatelna_specifikace" className="col-lg-3 col-form-label text-lg-right">
        Specifikace podání
      </label>
      <div className="col-lg-6">
        <div className="form-field-wrap">
          <textarea
            className="form-control"
            id="podatelna_specifikace"
            rows={3}
            name={"podatelna_specifikace"}
            value={data.podatelna_specifikace}
            readOnly={true}
          />
        </div>
      </div>
    </div>
  </fieldset>
);

export default PodatelnaSpecifikace;
