import React from "react";

// Import regular stylesheet

import { Input, TextArea, Form, Grid } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
}
class InformaceZadatel extends React.Component<AppProps> {
  private componentName = "form_informace_specifikace";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faQuestionCircle} className={"mr-2"} /> Specifikace žádosti o poskytnutí informace
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_specifikace" className="col-lg-3 col-form-label text-lg-right">
            Specifikace žádosti
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <textarea
                className="form-control"
                id="informace_specifikace"
                rows={3}
                name={"informace_specifikace"}
                value={this.props.data.informace_specifikace}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default InformaceZadatel;
