import React from "react";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import PopisProps from "./PopisProps";
import { PopisState } from "./PopisState";
import DatePickerInput from "../../DatePicker/DatePickerInput";
import InputTextarea from "../../InputTextarea";

export default class FormAkceLesPopis extends React.Component<PopisProps, PopisState> {
  private componentName = "form_akce_les_popis";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: PopisProps) {
    super(props);

    this.state = {
      nazev: "",
      termin: "",
      zpusob_zajisteni: "",
      blizsi_popis: "",
      pocet_ucastniku: 0
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  async handleChange(e: any) {
    this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof PopisState]: PopisState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  handleDateChange = val => {
    this.handleChange({
      currentTarget: {
        name: "termin",
        value: val
      }
    });
  };

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faClipboard} className={"mr-2"} /> Popis akce
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="nazev" className="col-lg-3 col-form-label text-lg-right">
            Název akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="nazev"
                name={"nazev"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.nazev}
                placeholder={"Název akce"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="termin" className="col-lg-3 col-form-label text-lg-right">
            Termín konání akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap no-gutters">
              <DatePickerInput
                onChange={this.handleDateChange}
                value={this.state.termin ? new Date(this.state.termin) : ""}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="pocet_ucastniku" className="col-lg-3 col-form-label text-lg-right">
            Předpokládaný počet účastníků
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="pocet_ucastniku"
                name={"pocet_ucastniku"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.pocet_ucastniku}
                placeholder={"Předpokládaný počet účastníků"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="blizsi_popis" className="col-lg-3 col-form-label text-lg-right">
            Bližší popis akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="blizsi_popis"
                name={"blizsi_popis"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.blizsi_popis}
                placeholder={"Bližší popis akce"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zpusob_zajisteni" className="col-lg-3 col-form-label text-lg-right">
            Způsob zajištění akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="zpusob_zajisteni"
                name={"zpusob_zajisteni"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.zpusob_zajisteni}
                placeholder={"Způsob zajištění akce"}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
