import * as React from "react";
import VyjadreniProps from "./VyjadreniProps";
import HeaderProps from "../form_prostredi_header/HeaderProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { Input } from "reactstrap";

export default class FormProstrediVyjadreni extends React.Component<VyjadreniProps> {
  constructor(props: HeaderProps) {
    super(props);
  }

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faBook} className={"mr-2"} /> Vyjádření k projektové dokumentaci
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="akce" className="col-lg-3 col-form-label text-lg-right">
            Akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="akce"
                name={"akce"}
                value={this.props.data.akce}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="katastralni_uzemi" className="col-lg-3 col-form-label text-lg-right">
            Katastrální území
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="katastralni_uzemi"
                name={"katastralni_uzemi"}
                value={this.props.data.katastralni_uzemi}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className="form-group row justify-content-md-center pt-3">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="vyjadreni_zaslat"
                name="vyjadreni_zaslat"
                className="custom-control-input"
                checked={this.props.data.vyjadreni_zaslat}
                disabled={true}
              />
              <label className="custom-control-label" htmlFor="vyjadreni_zaslat">
                vyjádření zaslat na adresu žadatele
              </label>
            </div>
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="vyjadreni_osobne"
                name="vyjadreni_osobne"
                className="custom-control-input"
                checked={this.props.data.vyjadreni_osobne}
                disabled={true}
              />
              <label className="custom-control-label" htmlFor="vyjadreni_osobne">
                vyjádření si převezmu osobně
              </label>
            </div>
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="vratit_dokumentaci"
                name="vratit_dokumentaci"
                className="custom-control-input"
                checked={this.props.data.vratit_dokumentaci}
                disabled={true}
              />
              <label className="custom-control-label" htmlFor="vratit_dokumentaci">
                požaduji vrátit projektovou dokumentaci
              </label>
            </div>
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="zaslat_na_urad"
                name="zaslat_na_urad"
                className="custom-control-input"
                checked={this.props.data.zaslat_na_urad}
                disabled={true}
              />
              <label className="custom-control-label" htmlFor="zaslat_na_urad">
                vyjádření zaslat s projektovou dokumentací na stavební úřad
              </label>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
