import React from "react";

class FormPsiHeader extends React.Component {
  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Přiznání k místnímu poplatku za psa</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FormPsiHeader;
