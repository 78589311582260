import React from "react";

class FormKapacitaHeader extends React.Component {
  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Žádost o poskytnutí informace</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FormKapacitaHeader;
