import React from "react";

// // Import regular stylesheet

// Interfaces
export interface AppProps {}

class FormPouceni extends React.Component<AppProps> {
  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <div>
        <div>Poučení</div>
        <div>
          Dojde-li ke změně údajů uvedených v ohlášení, je poplatník povinen tuto změnu oznámit do 15 dnů ode dne, kdy
          nastala (§ 14a zák. č. 565/1990 o místních poplatcích, ve znění pozdějších předpisů).
        </div>
      </div>
    );
  }
}

export default FormPouceni;
