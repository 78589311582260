import gql from "graphql-tag";

const SENT_FORM_QUERY = gql`
  query SentFormsQuery($id_sent_forms: String!) {
    sent_forms(id_sent_forms: $id_sent_forms) {
      id_sent_forms
      id_bought_forms
      data
      pubkey
      certificate_issuer
      form
      created
      organisationData {
        name
        inr
        www
      }
      attachments {
        id_sent_forms_attachments
        id_sent_forms
        file_name
        file
      }
    }
  }
`;

export default SENT_FORM_QUERY;
