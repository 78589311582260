import React from "react";

const InformaceSubjekt = ({ data }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Povinný subjekt
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Název:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.informace_subjekt_name}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Úřad:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.informace_subjekt_urad}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Adresa:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.informace_subjekt_adresa}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Město:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.informace_subjekt_mesto}</span>
      </div>
    </div>
  </React.Fragment>
);

export default InformaceSubjekt;
