import React, { Component } from "react";
import { SubjektProps } from "./SubjektProps";
import { SubjektState } from "./SubjektState";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";

export default class Subjekt extends Component<SubjektProps, SubjektState> {
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  private componentName = "form_subjekt";

  constructor(props) {
    super(props);

    this.state = {
      fyzicka_osoba: true,
      zadatel_name: "",
      zadatel_surname: "",
      zadatel_adresa: "",
      zadatel_dorucovaci_adresa: "",
      zadatel_ico: "",
      zadatel_rc: "",
      email: "",
      telefon: ""
    };

    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof SubjektState]: SubjektState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  handleChange(fyzicka_osoba) {
    let value = !!+fyzicka_osoba.target.value;
    this.setState({ fyzicka_osoba: value });
  }

  public render() {
    const { fyzicka_osoba } = this.state;
    const { componentName } = this.props;

    if (fyzicka_osoba) {
      return (
        <fieldset className={"mt-5"}>
          <legend>
            <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> {componentName}
          </legend>

          <div className="form-group row justify-content-md-center">
            <div className="row col-lg-6">
              <div className="col-6 custom-control custom-radio">
                <input
                  type="radio"
                  id="osoba1"
                  name="osoba"
                  className="custom-control-input"
                  value={1}
                  onChange={this.handleChange}
                  checked={this.state.fyzicka_osoba}
                />
                <label className="custom-control-label" htmlFor="osoba1">
                  Fyzická osoba
                </label>
              </div>

              <div className="col-6 custom-control custom-radio">
                <input
                  type="radio"
                  id="osoba2"
                  name="osoba"
                  value={0}
                  onChange={this.handleChange}
                  checked={!this.state.fyzicka_osoba}
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="osoba2">
                  Právnická osoba
                </label>
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_name" className="col-lg-3 col-form-label text-lg-right">
              Jméno
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_name"
                  name={"zadatel_name"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.zadatel_name}
                  placeholder={"Jméno"}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_surname" className="col-lg-3 col-form-label text-lg-right">
              Příjmení
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_surname"
                  name={"zadatel_surname"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.zadatel_surname}
                  placeholder={"Příjmení"}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_rc" className="col-lg-3 col-form-label text-lg-right">
              Rodné číslo / Datum narození
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_rc"
                  name={"zadatel_rc"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.zadatel_rc}
                  placeholder={"Rodné číslo / Datum narození"}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="telefon" className="col-lg-3 col-form-label text-lg-right">
              Telefon
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="tel"
                  className="form-control"
                  id="telefon"
                  name={"telefon"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.telefon}
                  placeholder={"Telefon"}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="email" className="col-lg-3 col-form-label text-lg-right">
              Email
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  name={"email"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.email}
                  placeholder={"Email"}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_adresa" className="col-lg-3 col-form-label text-lg-right">
              Adresa místa trvalého pobytu
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_adresa"
                  name={"zadatel_adresa"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.zadatel_adresa}
                  placeholder={"Adresa místa trvalého pobytu"}
                />
              </div>
            </div>
          </div>

          <div className={"form-group align-items-center row mt-4"}>
            <label htmlFor="zadatel_dorucovaci_adresa" className="col-lg-3 col-form-label text-lg-right">
              Doručovací adresa
            </label>
            <div className="col-lg-6">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="zadatel_dorucovaci_adresa"
                  name={"zadatel_dorucovaci_adresa"}
                  onChange={e => {
                    this.onChange(e);
                  }}
                  value={this.state.zadatel_dorucovaci_adresa}
                  placeholder={"Doručovací adresa"}
                />
                <small>pokud se liší od adresy místa trvalého pobytu</small>
              </div>
            </div>
          </div>
        </fieldset>
      );
    }

    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> {componentName}
        </legend>

        <div className="form-group row justify-content-md-center">
          <div className="row col-lg-6">
            <div className="col-6 custom-control custom-radio">
              <input
                type="radio"
                id="osoba1"
                name="osoba"
                className="custom-control-input"
                value={1}
                onChange={this.handleChange}
                checked={this.state.fyzicka_osoba}
              />
              <label className="custom-control-label" htmlFor="osoba1">
                Fyzická osoba
              </label>
            </div>

            <div className="col-6 custom-control custom-radio">
              <input
                type="radio"
                id="osoba2"
                name="osoba"
                value={0}
                onChange={this.handleChange}
                checked={!this.state.fyzicka_osoba}
                className="custom-control-input"
              />
              <label className="custom-control-label" htmlFor="osoba2">
                Právnická osoba
              </label>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_name" className="col-lg-3 col-form-label text-lg-right">
            Název
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_name"
                name={"zadatel_name"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.zadatel_name}
                placeholder={"Název společnosti"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_ico" className="col-lg-3 col-form-label text-lg-right">
            IČO
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_ico"
                name={"zadatel_ico"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.zadatel_ico}
                placeholder={"IČO"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="telefon" className="col-lg-3 col-form-label text-lg-right">
            Telefon
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="telefon"
                name={"telefon"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.telefon}
                placeholder={"Telefon"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="email" className="col-lg-3 col-form-label text-lg-right">
            Email
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="email"
                className="form-control"
                id="email"
                name={"email"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.email}
                placeholder={"Email"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa sídla
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_adresa"
                name={"zadatel_adresa"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.zadatel_adresa}
                placeholder={"Adresa sídla"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zadatel_dorucovaci_adresa" className="col-lg-3 col-form-label text-lg-right">
            Doručovací adresa
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="zadatel_dorucovaci_adresa"
                name={"zadatel_dorucovaci_adresa"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.zadatel_dorucovaci_adresa}
                placeholder={"Doručovací adresa"}
              />
              <small>pokud se liší od adresy sídla</small>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
