import React from "react";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import { TrvaniProps } from "./TrvaniProps";
import { TrvaniState } from "./TrvaniState";

export default class FormKomunikaceOsoba extends React.Component<TrvaniProps, TrvaniState> {
  private componentName = "form_komunikace_trvani";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: TrvaniProps) {
    super(props);

    this.state = {
      nazev: "",
      misto: "",
      ulice: "",
      trvani_zuk: 0
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  async handleChange(e: any) {
    this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof TrvaniState]: TrvaniState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> Popis akce
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="nazev" className="col-lg-3 col-form-label text-lg-right">
            Název akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="nazev"
                name={"nazev"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.nazev}
                placeholder={"Název akce"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="misto" className="col-lg-3 col-form-label text-lg-right">
            Místo akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="misto"
                name={"misto"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.misto}
                placeholder={"Místo akce"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="ulice" className="col-lg-3 col-form-label text-lg-right">
            Ulice
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="ulice"
                name={"ulice"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.ulice}
                placeholder={"Ulice"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="trvani_zuk" className="col-lg-3 col-form-label text-lg-right">
            Délka trvání ZUK
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="trvani_zuk"
                name={"trvani_zuk"}
                onChange={e => {
                  this.handleChange(e);
                }}
                value={this.state.trvani_zuk}
                placeholder={"Délka trvání ZUK"}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
