import React from "react";

class FormProstranstviHeader extends React.Component {
  private componentName = "form_odpad_header";

  constructor(props: any) {
    super(props);
  }

  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>
                Přiznání k místnímu poplatku za provoz systému shromažďování, sběru, přepravy, třídění, využívání a
                odstraňování komunálních odpadů
              </strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default FormProstranstviHeader;
