import React from "react";
import {AppProps} from './index';

const PodatelnaSpecifikace: React.FC = ({ data }: AppProps) => (
    <React.Fragment>
        <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
            Specifikace podání
        </div>
        <div className={"row no-gutters"}>
            <p>{data.podatelna_specifikace}</p>
        </div>
    </React.Fragment>
);

export default PodatelnaSpecifikace;
