import React from "react";

const InformaceHeader = () => (
  <React.Fragment>
    <div style={{ textAlign: "left", fontWeight: 800 }}>
      <h2>Žádost o poskytnutí informace</h2>
    </div>
  </React.Fragment>
);

export default InformaceHeader;
