import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
// import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import "isomorphic-unfetch";
import "bootstrap";

// import { persistCache } from "apollo-cache-persist";

import App from "./components/App";

import config from "../config/config";

const rootEl = document.getElementById("root");

/*
 * Semantic UI CSS
 */

/*
 * Apollo Client
 */
// const cache = new InMemoryCache({
//   dataIdFromObject: (object: any) => {
//     switch (object.__typename) {
//       default:
//         return defaultDataIdFromObject(object); // fall back to default
//     }
//   }
// });
//
// persistCache({
//   cache,
//   storage: window.sessionStorage
// });

const client = new ApolloClient({
  uri: config.graphql.serverURI,
  // cache,
  clientState: {
    defaults: {
      isConnected: true
    },
    resolvers: {
      Mutation: {
        updateNetworkStatus: (_, { isConnected }, { cache }) => {
          cache.writeData({ data: { isConnected } });
          return null;
        }
      }
    }
  }
});

render(
  <ApolloProvider client={client}>
    <AppContainer>
      <App />
    </AppContainer>
  </ApolloProvider>,
  rootEl
);

// serviceWorker.register();

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const App = require("./components/App").default;
    render(
      <ApolloProvider client={client}>
        <AppContainer>
          <App />
        </AppContainer>
      </ApolloProvider>,
      rootEl
    );
  });
}
