import React from "react";
import { Input } from "reactstrap";

const InputTextarea = (props: any) => {
  return (
    <Input
      type="textarea"
      onKeyPress={e => {
        //enter is disabled on form to prevent him from submitting on input enter, but we need to allow enter on textarea
        if (e.which === 13) {
          props.onChange({
            currentTarget: {
              name: props.id,
              value: `${props.value}\r\n`
            }
          });
        }
      }}
      {...props}
    />
  );
};

export default InputTextarea;
