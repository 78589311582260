import React from "react";
import HeaderProps from "./HeaderProps";

export default class FormDrevinyHeader extends React.Component<HeaderProps> {
  private componentName = "form_dreviny_header";

  constructor(props: HeaderProps) {
    super(props);
  }

  public componentDidMount = async () => {
    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.props.data
    );
  };

  public render() {
    return (
      <div className="row mb-4 mt-5">
        <div className="col">
          <div className={"text-center"}>
            <h2>
              <strong>Žádost o povolení ke kácení dřevin</strong>
            </h2>
          </div>
        </div>
      </div>
    );
  }
}
