import React from "react";
import StoreLocal from "../../../libs/LocalStorage";
import PoplatekProps from "./PoplatekProps";
import { PoplatekState } from "./PoplatekState";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

class FormOdpadZastupce extends React.Component<PoplatekProps, PoplatekState> {
  private componentName = "form_odpad_poplatek";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);

  constructor(props: PoplatekProps) {
    super(props);

    this.state = {
      povinnost: "",
      poplatnik_zastupce: false,
      poplatnik: [
        {
          jmeno: "",
          prijmeni: "",
          datum_narozeni: "",
          adresa: ""
        }
      ]
    };

    this.onChange = this.onChange.bind(this);
    this.changeCheckbox = this.changeCheckbox.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState(({
      [e.currentTarget.name]: e.currentTarget.value
    } as unknown) as { [K in keyof PoplatekState]: PoplatekState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  handleAddTaxPayer = async () => {
    this.setState({
      poplatnik: this.state.poplatnik.concat([
        {
          jmeno: "",
          prijmeni: "",
          datum_narozeni: "",
          adresa: ""
        }
      ])
    });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  handleRemoveTaxPayer = idx => async () => {
    this.setState({
      poplatnik: this.state.poplatnik.filter((s, sidx) => idx !== sidx)
    });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  handleTaxPayerDescChange = idx => async evt => {
    const newTaxPayers = this.state.poplatnik.map((dog, sidx) => {
      if (idx !== sidx) return dog;
      return { ...dog, [evt.currentTarget.name]: evt.currentTarget.value };
    });
    this.setState({ poplatnik: newTaxPayers });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  private async changeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    await this.setState(({
      [e.currentTarget.name]: e.target.checked
    } as unknown) as { [K in keyof PoplatekState]: PoplatekState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faWallet} className={"mr-2"} /> Poplatek
        </legend>
        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="povinnost" className="col-lg-3 col-form-label text-lg-right">
            Poplatková povinnost vznikla od
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="povinnost"
                name={"povinnost"}
                onChange={this.onChange}
                value={this.state.povinnost}
                placeholder={"Poplatková povinnost vznikla od"}
              />
            </div>
          </div>
        </div>

        <div className="form-group row justify-content-md-center pt-3">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="poplatnik_zastupce"
                name="poplatnik_zastupce"
                className="custom-control-input"
                value={+this.state.poplatnik_zastupce}
                checked={this.state.poplatnik_zastupce}
                onChange={e => this.changeCheckbox(e)}
              />
              <label className="custom-control-label" htmlFor="poplatnik_zastupce">
                Poplatník je zároveň společný zástupce
              </label>
            </div>
          </div>
        </div>

        {this.state.poplatnik.map((poplatnik, idx) => (
          <div className={"card mt-4"} key={idx}>
            <div className="card-header text-center text-white bg-primary">{idx + 1}. poplatník</div>
            <div className={"card-body"}>
              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="jmeno" className="col-lg-3 col-form-label text-lg-right">
                  Jméno
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="jmeno"
                      name={"jmeno"}
                      onChange={this.handleTaxPayerDescChange(idx)}
                      value={poplatnik.jmeno}
                      placeholder={"Jméno"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="prijmeni" className="col-lg-3 col-form-label text-lg-right">
                  Příjmení
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="prijmeni"
                      name={"prijmeni"}
                      onChange={this.handleTaxPayerDescChange(idx)}
                      value={poplatnik.prijmeni}
                      placeholder={"Příjmení"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="datum_narozeni" className="col-lg-3 col-form-label text-lg-right">
                  RČ / datum narození
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="datum_narozeni"
                      name={"datum_narozeni"}
                      onChange={this.handleTaxPayerDescChange(idx)}
                      value={poplatnik.datum_narozeni}
                      placeholder={"RČ / datum narození"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="adresa" className="col-lg-3 col-form-label text-lg-right">
                  Adresa
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="adresa"
                      name={"adresa"}
                      onChange={this.handleTaxPayerDescChange(idx)}
                      value={poplatnik.adresa}
                      placeholder={"Adresa"}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <Button
                  type="button"
                  onClick={this.handleRemoveTaxPayer(idx)}
                  className="btn btn-sm btn-outline-primary"
                >
                  Odebrat poplatníka
                </Button>
              </div>
            </div>
          </div>
        ))}

        <div className="row d-flex justify-content-center mt-4">
          <Button type="button" onClick={this.handleAddTaxPayer} className="btn btn-sm btn-outline-primary">
            Přidat poplatníka
          </Button>
        </div>
      </fieldset>
    );
  }
}

export default FormOdpadZastupce;
