import React from "react";

// Import Local storage
import StoreLocal from "../../../libs/LocalStorage";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  formDeployed: any;
}
export interface AppState {
  odpad_osoba_osoba: any;
  odpad_osoba_jmeno: any;
  odpad_osoba_rc: any;
  odpad_osoba_adresa: any;
  odpad_osoba_poplatek: any;
}

class FormOdpadOsoba extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  constructor(props: AppProps) {
    super(props);
    this.state = {
      odpad_osoba_osoba: this.storeLocal.getFromLocalStorage("odpad_osoba_osoba") || "",
      odpad_osoba_jmeno: this.storeLocal.getFromLocalStorage("odpad_osoba_jmeno") || "",
      odpad_osoba_rc: this.storeLocal.getFromLocalStorage("odpad_osoba_rc") || "",
      odpad_osoba_adresa: this.storeLocal.getFromLocalStorage("odpad_osoba_adresa") || "",
      odpad_osoba_poplatek: this.storeLocal.getFromLocalStorage("odpad_osoba_poplatek") || ""
    };
    this.onChange = this.onChange.bind(this);
  }

  public onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as { [K in keyof AppState]: AppState[K] });
    this.storeLocal.saveToLocalStorage(`${e.currentTarget.name}`, `${e.currentTarget.value}`);
  };

  private clearStorage() {
    this.storeLocal.clearLocalStorage();
  }

  public render() {
    // Clear the storage after form deployed
    if (this.props.formDeployed) {
      this.clearStorage();
    }
    return (
      <div>
        <div>Fyzická osoba:</div>
        <div>
          <div>
            <label>
              <input
                type="radio"
                name="odpad_osoba_osoba"
                value="pobyt"
                checked={this.state.odpad_osoba_osoba === "pobyt"}
                onChange={e => {
                  this.onChange(e);
                }}
              />
              Trvalý pobyt
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="odpad_osoba_osoba"
                value="90"
                checked={this.state.odpad_osoba_osoba === "90"}
                onChange={e => {
                  this.onChange(e);
                }}
              />
              Cizinec s povoleným trvalým nebo přechodným pobytem nad 90 dní
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="odpad_osoba_osoba"
                value="3"
                checked={this.state.odpad_osoba_osoba === "3"}
                onChange={e => {
                  this.onChange(e);
                }}
              />
              Cizinec s přechodným pobytem nad 3 měsíce
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="odpad_osoba_osoba"
                value="Azyl"
                checked={this.state.odpad_osoba_osoba === "Azyl"}
                onChange={e => {
                  this.onChange(e);
                }}
              />
              Azyl
            </label>
          </div>
        </div>

        <div>
          <span>Jméno, příjmení fyzické osoby-poplatníka/společného zástupce</span>
          <span>
            <input
              name={"odpad_osoba_jmeno"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.odpad_osoba_jmeno}
            />
          </span>
        </div>

        <div>
          <span>Rodné číslo/datum narození</span>
          <span>
            <input
              name={"odpad_osoba_rc"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.odpad_osoba_rc}
            />
          </span>
        </div>

        <div>
          <span>Adresa pobytu</span>
          <span>
            <input
              name={"odpad_osoba_adresa"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.odpad_osoba_adresa}
            />
          </span>
        </div>

        <div>
          <span>Poplatková povinnost vznikla od</span>
          <span>
            <input
              name={"odpad_osoba_poplatek"}
              onChange={e => {
                this.onChange(e);
              }}
              value={this.state.odpad_osoba_poplatek}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default FormOdpadOsoba;
