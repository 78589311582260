import "../../styles/main.css";

require("bootstrap");
require("jquery");
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RenderPdf from "../RenderPdf";

// GraphQL Queryy
import { Query } from "react-apollo";
import ORGANISATIONS_QUERY from "../../queries/organisations";

import Organisation from "../Organisation";
import RenderResult from "../RenderResult";
import RenderForm from "../RenderForm";
import { Container } from "reactstrap";

export interface Data {
  organisations: {
    id_organisations: string;
    name: string;
    slug: string;
  };
}

const NotFound = () => <div>Page not found</div>;

class App extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter basename="/qhlas/">
        <Container>
          <Switch>
            <Route exact={true} path="/qhlas/" render={() => (window.location.href = "https://vhodne-uverejneni.cz")} />
            <Route exact={true} path="/form/:id" component={RenderResult} />
            <Route exact={true} path="/pdf/:id" component={RenderPdf} />
            <Route exact={true} path="/:organisation" component={Organisation} />
            <Route exact={true} path="/:organisation/:form" component={RenderForm} />
            <NotFound />
          </Switch>
        </Container>
      </BrowserRouter>
    );
  }
}

export default App;
