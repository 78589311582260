import React from "react";
import { printBool } from "../../../helpers";

const Pdf = ({ data: { adresa, jina_osoba, jmeno, prijmeni, telefon } }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Osoba zodpovědná za zvláštní užívání
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Osoba zodpovědná je jiná než pořadatel:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{printBool(jina_osoba)}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Jméno:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{jmeno}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Příjmení:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{prijmeni}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Adresa:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{adresa}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Telefon:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{telefon}</span>
      </div>
    </div>
  </React.Fragment>
);

export default Pdf;
