import * as React from "react";
import { ZadostProps } from "./ZadostProps";
import typZadosti from "./typZadosti";

const ProstrediZadostPdf = ({ data: { ucel } }: ZadostProps) => (
  <React.Fragment>
    <div className={"row mt-5"}>
      <div className={"label-title col-3"}>Typ žádosti:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{typZadosti[ucel]}</span>
      </div>
    </div>
  </React.Fragment>
);

export default ProstrediZadostPdf;
