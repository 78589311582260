import gql from "graphql-tag";

const BOUGHT_FORM_QUERY = gql`
  query BoughtFormQuery($slug_organisation: String!, $slug_forms: String!) {
    bought_form(slug_organisation: $slug_organisation, slug_forms: $slug_forms) {
      id_bought_forms
      id_organisations
      id_forms
      is_public
      form {
        name
        header {
          id_components
          name
          slug
        }
        components {
          id_components
          name
          slug
        }
        footer {
          id_components
          name
          slug
        }
      }
    }
  }
`;

export default BOUGHT_FORM_QUERY;
