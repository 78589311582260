import React, { ChangeEvent } from 'react';
import { Input, UncontrolledTooltip } from 'reactstrap';
import Radio from '../../FormComponents/Radio';
import DropzoneUpload from '../../Dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { AvField, AvCheckbox } from 'availity-reactstrap-validation';

const MAX_FILES = {
  PREMIUM: 5,
  PROFI: 3,
  FREE: 1
};

export const INCIDENT_KATEGORIE_TYP = [
  { value: 'tres-cin', label: 'znak trestního činu' },
  { value: 'prestupek', label: 'znak přestupku' },
  { value: 'porusuje-zakon', label: 'porušuje tento zákon, nebo' },
  { value: 'porusuje-predpis', label: 'porušuje jiný právní předpis nebo předpis EU' },
  { value: 'jine', label: 'jiné' }
];

export const INCIDENT_KATEGORIE = [
  { value: 'zadavani-verejnych-zakazek', label: 'hospodářské soutěže, veřejných dražeb a zadávání veřejných zakázek' },
  {
    value: 'fin-sluzby',
    label: 'fin. služeb, povinného auditu a jiných ověřovacích služeb, fin. produktů a fin. trhů'
  },
  { value: 'dane-z-prijmu', label: 'daně z příjmů právnických osob' },
  { value: 'bezpecnost-dopravy', label: 'bezpečnosti dopravy, přepravy a provozu na pozemních komunikacích' },
  { value: 'ochrana-zp', label: 'ochrany životního prostředí' },
  { value: 'jaderna-bezpecnost', label: 'radiační ochrany a jaderné bezpečnosti' },
  { value: 'bezpecnost-potravin', label: 'bezpečnosti potravin a krmiv a ochrany zvířat a jejich zdraví' },
  { value: 'verejne-zdravi', label: 'ochrany vnitřního pořádku a bezpečnosti, života a zdraví' },
  { value: 'ochrana-spotrebitele', label: 'ochrany spotřebitele' },
  {
    value: 'ochrana-osobnich-udaju',
    label: 'ochrany osobních údajů, soukromí a bezpečnosti sítí elektronických komunikací a informačních systémů'
  },
  { value: 'ochrana-fin-zajmu', label: 'ochrany fin. zájmů EU' },
  {
    value: 'fungovani-trhu',
    label: 'fungování vnitřního trhu včetně ochrany hospodářské soutěže a státní podpory podle práva Evropské unie'
  },
  { value: 'legalizace', label: 'předcházení legalizaci výnosů z trestné činnosti a financování terorismu' },
  { value: 'vyroba', label: 'souladu s požadavky na výrobky včetně jejich bezpečnosti' },
  { value: 'jine', label: 'jiné' }
];

export const INCIDENT_VZTAH = [
  { value: 'zmestani', label: 'zaměstnání' },
  { value: 'vojenska-sluzba', label: 'vojenská služba' },
  { value: 'osvc', label: 'OSVČ' },
  { value: 'vykon-prav', label: 'výkon práv spojených s účastí v právnické osobě' },
  { value: 'vykon-fce', label: 'výkon funkce člena orgánu právnické osoby' },
  {
    value: 'plneni-ukolu',
    label: 'plnění úkolů v rámci činnosti právnické osoby, v jejím zájmu, jejím jménem nebo na její účet'
  },
  { value: 'sprava', label: 'správa svěřenského fondu' },
  { value: 'dobrovolnicka-cinnost', label: 'dobrovolnická činnost' },
  { value: 'praxe', label: 'odborná praxe, stáž' },
  { value: 'uchazeni', label: 'ucházení se o práci nebo jinou obdobnou činnost' },
  { value: 'vykon-prav-ze-smlouvy', label: 'výkon práv a povinností vyplývajících ze smlouvy' },
  { value: 'dodavatel', label: 'dodavatel (smlouva na veřejnou zakázku)' }
];

export default class ZalozeniNovehoIncidentu extends React.Component<any, any> {
  constructor (props) {
    super(props);

    this.state = {
      nazev: '',
      kontaktniOsoba: {
        email: '',
        jmeno: '',
        prijmeni: '',
        datumNarozeni: '',
        vztah: '',
        adresa: '',
      },
      kategorie: '',
      kategorieTyp: '',
      popisIncidentu: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePerson = this.handleChangePerson.bind(this);
    this.handleAttachment = this.handleAttachment.bind(this);
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.data !== this.props.data || nextProps.org !== this.props.org) {
      return true;
    } else {
      return false;
    }
  }

  handleChange (event: React.ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = event;
    const { updateData, officeId, formId, componentId, componentSlug } = this.props;
    console.log('handle state', currentTarget);

    this.setState({ ...this.state, [currentTarget.name]: currentTarget.value }, () =>
      updateData(officeId, formId, componentId, componentSlug, this.state)
    );

    updateData(officeId, formId, componentId, componentSlug, this.state);
  }

  handleChangePerson (event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    const { updateData, officeId, formId, componentId, componentSlug } = this.props;

    const kontaktniOsoba = {
      ...this.state.kontaktniOsoba,
      [target.name]: target.value
    };

    this.setState({ kontaktniOsoba }, () => updateData(officeId, formId, componentId, componentSlug, this.state));
  }

  handleAttachment (acceptedFiles: any) {
    this.props.handleAttachments(acceptedFiles);
  }

  render () {
    const { data } = this.props;
    const { updateData, officeId, formId, componentId, componentSlug, org } = this.props;

    const isProfi = org.role === 'profi';
    const isPremium = org.role === 'premium';

    return (
      <>
        <div className="text-center">
          Máte pocit, že jste na pracovišti diskriminovaní, obtěžovaní, jste obětí šikany nebo svědkem korupce?
          <br />
          <strong>Ohlaste to!</strong>
          <br />
          Prostřednictvím online formuláře <strong>qHlas</strong> můžete ohlásit incident snadno a rychle.
          <br />
          <div style={{ color: 'red' }}>
            Odesláním formuláře si je oznamovatel vědom, že učinil vědomě pravdivé oznámení.
          </div>
        </div>
        <fieldset className={'mt-5'}>
          <div className="d-flex justify-content-end">
            Stav: <b className="pl-1">Nový</b>
          </div>
          <div className={'form-group align-items-center row'}>
            <label htmlFor="nazev" className="col-lg-12 col-form-label" style={{ fontSize: '1.1rem' }}>
              Název incidentu <b className="text-danger">*</b>
              <span id="nazevTooltip" className="pl-1 text-secondary">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </span>
              <UncontrolledTooltip target="nazevTooltip">
                Název incidentu by měl být stručný a jasný, bez použití vulgarismů. Pod Vámi uvedeným názvem bude
                incident evidován a dále zpracováván.
              </UncontrolledTooltip>
            </label>
            <div className="col-lg-12">
              <div className="form-field-wrap">
                <AvField
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Prosím zadejte název incidentu'
                    }
                  }}
                  type="text"
                  className="form-control"
                  id="nazev"
                  name={'nazev'}
                  value={data && data.nazev}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <fieldset style={{ minHeight: 345 }}>
                <legend>
                  Incident nahlásil
                  <span id="incidentTooltip" className="pl-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                  <UncontrolledTooltip target="incidentTooltip">
                    V případě, že oznamovatel nevyplní údaje o jménu, příjmení, datu narození a kontaktní adrese, nebo
                    jiné údaje, z nichž
                    je možné odvodit jeho totožnost, se na něj <strong>nebude vztahovat ochrana dle zákona na ochranu
                    oznamovatelů</strong>. Při odeslání incidentu Vám bude vygenerován odkaz, na základě kterého můžete
                    zkontrolovat stav Vámi podaného incidentu.
                  </UncontrolledTooltip>
                </legend>

                <div className="row">
                  <div className="col-lg-6">

                    <div className={'form-group align-items-center row'}>
                      <label htmlFor="jmeno" className="col-lg-12 col-form-label">
                        Jméno
                      </label>
                      <div className="col-lg-12">
                        <div className="form-field-wrap">
                          <Input
                            type="text"
                            className="form-control"
                            id="jmeno"
                            name={'jmeno'}
                            onChange={this.handleChangePerson}
                            value={data && data.kontaktniOsoba.jmeno}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={'form-group align-items-center row'}>
                      <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                        Příjmení
                      </label>
                      <div className="col-lg-12">
                        <div className="form-field-wrap">
                          <Input
                            type="text"
                            className="form-control"
                            id="prijmeni"
                            name={'prijmeni'}
                            onChange={this.handleChangePerson}
                            value={data && data.kontaktniOsoba.prijmeni}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={'form-group align-items-center row'}>
                      <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                        Datum narození
                      </label>
                      <div className="col-lg-12">
                        <div className="form-field-wrap">
                          <input className="input-date" type="date" data-date="" data-date-format="DD MMMM YYYY"
                                 id="datumNarozeni"
                                 name={'datumNarozeni'}
                                 onChange={this.handleChangePerson}
                                 value={data && data.kontaktniOsoba.datumNarozeni} />
                        </div>
                      </div>
                    </div>
                    <div className={'form-group align-items-center row'}>
                      <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                        Kontaktní adresa
                      </label>
                      <div className="col-lg-12">
                        <div className="form-field-wrap">
                          <Input
                            type="text"
                            className="form-control"
                            id="adresa"
                            name={'adresa'}
                            onChange={this.handleChangePerson}
                            value={data && data.kontaktniOsoba.adresa}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className={'form-group align-items-center row'}>
                      <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                        Vztah oznamovatele k povinnému subjektu
                      </label>
                      <div className="col-lg-12">
                        <div className="form-field-wrap">
                          <Radio
                            name="vztah"
                            options={INCIDENT_VZTAH}
                            value={data && data.kontaktniOsoba.vztah}
                            onChange={val => this.handleChangePerson({
                              target: {
                                value: val,
                                name: 'vztah'
                              }
                            } as ChangeEvent<any>)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>


            <div className="col-lg-12 mt-5">
              <fieldset style={{ minHeight: 345 }}>
                <legend>
                  Kategorie <b className="text-danger">*</b>
                  <span id="kategorieTooltip" className="pl-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                  <UncontrolledTooltip target="kategorieTooltip">
                    Můžete si vybrat z následujících kategorií a zařadit podle ní incident. Kategorie jsou specifikované
                    podle zákona na ochranu oznamovatelů. Oznamovatel si musí vybrat z každého sloupce jednu kategorie.
                    Pokud si není jistý, může vybrat kategorii Jiné a více kategorii specifikovat v popisu incidentu.
                  </UncontrolledTooltip>
                </legend>
                <div className="row">
                  <div className="col-lg-6">
                    <Radio
                      name="kategorieTyp"
                      options={INCIDENT_KATEGORIE_TYP}
                      value={data && data.kategorieTyp}
                      onChange={value =>
                        this.setState({ kategorieTyp: value }, () =>
                          updateData(officeId, formId, componentId, componentSlug, this.state)
                        )
                      }
                      required
                    />
                  </div>
                  <div className="col-lg-6">
                    <Radio
                      name="kategorie"
                      options={INCIDENT_KATEGORIE}
                      value={data && data.kategorie}
                      onChange={value =>
                        this.setState({ kategorie: value }, () =>
                          updateData(officeId, formId, componentId, componentSlug, this.state)
                        )
                      }
                      required
                    />
                  </div>
                </div>
              </fieldset>
            </div>


            <div className="col-lg-12 mt-5">
              <fieldset>
                <legend>
                  Popis incidentu <b className="text-danger">*</b>
                  <span id="popisTooltip" className="pl-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                  <UncontrolledTooltip target="popisTooltip">
                    Popis incidentu by neměl obsahovat žádné vulgarismy, jinak se může stát, že Váš incident nebude
                    evidován. Popis by měl být srozumitelný a věcný, aby bylo jasné, čeho se incident týká a mohl se
                    důkladně prošetřit.
                  </UncontrolledTooltip>
                </legend>

                <AvField
                  type="textarea"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Prosím zadejte popis incidentu'
                    }
                  }}
                  rows={10}
                  id="popisIncidentu"
                  name="popisIncidentu"
                  value={data && data.popisIncidentu}
                  onChange={this.handleChange}
                />
              </fieldset>
            </div>

            <div className="col-lg-12 mt-5">
              <fieldset>
                <legend>
                  Přílohy k incidentu
                  <span id="prilohyTooltip" className="pl-1 text-secondary">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </span>
                  <UncontrolledTooltip target="prilohyTooltip">
                    Zde můžete vložit přílohy, které poukazují na skutečnosti provedeného incidentu. Maximální velikost
                    přílohy je 20MB.
                  </UncontrolledTooltip>
                </legend>

                <DropzoneUpload maxFiles={isPremium ? MAX_FILES.PREMIUM : isProfi ? MAX_FILES.PROFI : MAX_FILES.FREE}
                                handleAddAcceptedAtachments={this.handleAttachment} />
              </fieldset>
            </div>
            <div className="col-lg-12 mt-5">
              <fieldset>
                {/*<legend>*/}
                {/*  Kontakt*/}
                {/*</legend>*/}
                <div className={'form-group align-items-center row'}>
                  <label htmlFor="email" className="col-lg-12 col-form-label" style={{ fontSize: '1.1rem' }}>
                    Kontaktní e-mail
                    <span id="emailTooltip" className="pl-1 text-secondary">
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    <UncontrolledTooltip target="emailTooltip">
                      Zadání Vaší emailové adresy je dobrovolné. Při odeslání incidentu Vám bude vygenerován odkaz, na
                      základě kterého můžete zkontrolovat stav Vámi podaného incidentu.
                      <br />
                      <br />
                      Při zadání Vaší emailové adresy bude zpětná komunikace s Vaší osobou mnohem jednodušší. Budete
                      přímo upozorňováni na případné změny ke stavu incidentu.
                    </UncontrolledTooltip>
                  </label>
                  <div className="col-lg-12">
                    <div className="form-field-wrap">
                      <AvField
                        type="email"
                        className={'form-control'}
                        id="email"
                        validate={{
                          email: {
                            value: true,
                            errorMessage: 'Prosím zadejte email ve správném tvaru'
                          }
                        }}
                        // error={this.state.emailError}
                        // placeholder={'Kontaktní email'}
                        name={'email'}
                        onChange={this.handleChangePerson}
                        value={data && data.kontaktniOsoba.email}
                        autoComplete={'off'}
                        // required
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </fieldset>
          <div className="col-12 custom-control custom-checkbox mt-4 mx-5">
            <AvField
              type="checkbox"
              required
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Položka je povinná'
                }
              }}
              id="souhlas"
              name="souhlas"
              style={{width: 'auto', height: 'auto', left: 0}}
            />
            <label htmlFor="souhlas">
              Souhlas s poskytováním služeb formuláře qHlas podle{' '}
              <a target="_blank" href="https://qhlas.cz/vseobecne-obchodni-podminky/">Provozních a všeobecných obchodních podmínek</a>
            </label>
          </div>
      </>
    );
  }
}
