import React from "react";
import PoplatekProps from "./PoplatekProps";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet } from "@fortawesome/free-solid-svg-icons";

class FormOdpadZastupce extends React.Component<PoplatekProps> {
  constructor(props: PoplatekProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faWallet} className={"mr-2"} /> Poplatek
        </legend>
        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="povinnost" className="col-lg-3 col-form-label text-lg-right">
            Poplatková povinnost vznikla od
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="povinnost"
                name={"povinnost"}
                value={this.props.data.povinnost}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className="form-group row justify-content-md-center pt-3">
          <div className="row col-lg-6">
            <div className="col-12 custom-control custom-checkbox">
              <input
                type="checkbox"
                id="poplatnik_zastupce"
                name="poplatnik_zastupce"
                className="custom-control-input"
                value={+this.props.data.poplatnik_zastupce}
                checked={this.props.data.poplatnik_zastupce}
                readOnly={true}
              />
              <label className="custom-control-label" htmlFor="poplatnik_zastupce">
                Poplatník je zároveň společný zástupce
              </label>
            </div>
          </div>
        </div>

        {this.props.data.poplatnik.map((poplatnik, idx) => (
          <div className={"card mt-4"} key={idx}>
            <div className="card-header text-center text-white bg-primary">{idx + 1}. poplatník</div>
            <div className={"card-body"}>
              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="jmeno" className="col-lg-3 col-form-label text-lg-right">
                  Jméno
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="jmeno"
                      name={"jmeno"}
                      value={poplatnik.jmeno}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="prijmeni" className="col-lg-3 col-form-label text-lg-right">
                  Příjmení
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="prijmeni"
                      name={"prijmeni"}
                      value={poplatnik.prijmeni}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="datum_narozeni" className="col-lg-3 col-form-label text-lg-right">
                  RČ / datum narození
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="datum_narozeni"
                      name={"datum_narozeni"}
                      value={poplatnik.datum_narozeni}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="adresa" className="col-lg-3 col-form-label text-lg-right">
                  Adresa
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="adresa"
                      name={"stari"}
                      value={poplatnik.adresa}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </fieldset>
    );
  }
}

export default FormOdpadZastupce;
