import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import PopisProps from "./PopisProps";
import InputTextarea from "../../InputTextarea";

export default class FormAkceLestPopis extends React.Component<PopisProps> {
  constructor(props: PopisProps) {
    super(props);
  }

  render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faClipboard} className={"mr-2"} /> Popis akce
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="nazev" className="col-lg-3 col-form-label text-lg-right">
            Název akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="nazev"
                name={"nazev"}
                value={this.props.data.nazev}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="termin" className="col-lg-3 col-form-label text-lg-right">
            Termín konání akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                className="form-control"
                id="termin"
                name={"termin"}
                value={this.props.data.termin ? new Date().toLocaleDateString("cs-CZ") : "nevyplněno"}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="pocet_ucastniku" className="col-lg-3 col-form-label text-lg-right">
            Předpokládaný počet účastníků
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="pocet_ucastniku"
                name={"pocet_ucastniku"}
                value={this.props.data.pocet_ucastniku}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="blizsi_popis" className="col-lg-3 col-form-label text-lg-right">
            Bližší popis akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="blizsi_popis"
                name={"blizsi_popis"}
                value={this.props.data.blizsi_popis}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="zpusob_zajisteni" className="col-lg-3 col-form-label text-lg-right">
            Způsob zajištění akce
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <InputTextarea
                className="form-control"
                id="zpusob_zajisteni"
                name={"zpusob_zajisteni"}
                value={this.props.data.zpusob_zajisteni}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}
