import React from 'react';

const PodatelnaHeader: React.FC = () => (
    <div className="row mb-4">
        <div className="col">
            <div className={"text-center"}>
                <h2>
                    <strong>Obecné podání - podatelna</strong>
                </h2>
            </div>
        </div>
    </div>
);

export default PodatelnaHeader