import * as React from "react";
import { HeaderProps } from "./HeaderProps";

const ProstrediHeaderPdf = (props: HeaderProps) => (
  <div style={{ textAlign: "left", fontWeight: 800 }}>
    <h2>Žádost o souhrnné stanovisko odboru životního prostředí</h2>
  </div>
);

export default ProstrediHeaderPdf;
