import React from "react";

// Import regular stylesheet

import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";

// Interfaces

export interface AppProps {
  data: any;
  name?: string;
}
class InformaceZadatel extends React.Component<AppProps> {
  private componentName = "form_kapacita_poplatnik";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUniversity} className={"mr-2"} /> Žadatel
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_name" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_name"
                name={"informace_zadatel_name"}
                value={this.props.data.informace_zadatel_name}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_surname" className="col-lg-3 col-form-label text-lg-right">
            Příjmení
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_surname"
                name={"informace_zadatel_surname"}
                value={this.props.data.informace_zadatel_surname}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_rc" className="col-lg-3 col-form-label text-lg-right">
            Rodné číslo / Datum narození
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_rc"
                name={"informace_zadatel_rc"}
                value={this.props.data.informace_zadatel_rc}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa místa trvalého pobytu poplatníka
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_adresa"
                name={"informace_zadatel_adresa"}
                value={this.props.data.informace_zadatel_adresa}
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_dorucovaci_adresa" className="col-lg-3 col-form-label text-lg-right">
            Doručovací adresa poplatníka
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_dorucovaci_adresa"
                name={"informace_zadatel_dorucovaci_adresa"}
                value={this.props.data.informace_zadatel_dorucovaci_adresa}
                readOnly={true}
              />
              <small>pokud se liší od adresy místa trvalého pobytu</small>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default InformaceZadatel;
