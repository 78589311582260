import React from "react";

const InformaceSubjekt = ({ data }) => (
  <React.Fragment>
    <div className={"section-title mt-5"} style={{ textDecoration: "underline" }}>
      Podávám přiznání k platbě místního poplatku za užívání veřejného prostranství na místo a účel užívání
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Dohodnuto dne:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.prostranstvi_priznani_dne}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>
        Počet m<sup>2</sup>:
      </div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.prostranstvi_priznani_m2}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Poplatková povinnost od – do:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.prostranstvi_priznani_povinnost}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Počet dnů:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.prostranstvi_priznani_povinnost_dny}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Sazba poplatku za 1 den (dle OZV, čl. 15):</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.prostranstvi_priznani_sazba}</span>
      </div>
    </div>
    <div className={"row"}>
      <div className={"label-title col-3"}>Poplatek celkem:</div>
      <div className={"col-9"}>
        <span className={"underline-dotted"}>{data.prostranstvi_priznani_poplatek}</span>
      </div>
    </div>
  </React.Fragment>
);

export default InformaceSubjekt;
