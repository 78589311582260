import React from "react";

const ProstranstviHeader = () => (
  <React.Fragment>
    <div style={{ textAlign: "left", fontWeight: 800 }}>
      <h2>Přiznání místního poplatku za užívání veřejného prostranství</h2>
    </div>
  </React.Fragment>
);

export default ProstranstviHeader;
