import React from "react";

// GraphQL query
import { Query } from "react-apollo";
import ORGANISATION_QUERY from "../../queries/organisation";

// Type interfaces
import { AppProps, Data, Variables } from "./types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Alert from "reactstrap/lib/Alert";

class Header extends React.Component<AppProps> {
  private headerId: string = "header";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    const id_organisations = this.props.id_organisations;
    return (
      <Query<Data, Variables> query={ORGANISATION_QUERY} variables={{ id_organisations }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <FontAwesomeIcon icon={faSpinner} />
              </div>
            );
          if (error) return <div>Error loading tha data: {error.message}</div>;
          const _data: any = data;
          if (_data) {
            this.props.updateData(this.headerId, this.props.id_bought_forms, _data);
            const organisation: any = _data.organisation || "";
            const street_name = organisation.street_name || "";
            const home_number = organisation.home_number || "";
            const zip_code = organisation.zip_code || "";
            let address = "";
            if (street_name && home_number) {
              address = street_name.concat(", ", home_number);
            }
            let municipality = "";
            if (organisation && zip_code) {
              municipality = organisation.city.concat(", ", zip_code);
            }
            const registryVerified: any = organisation.registry_verified || false;
            return (
              <div className={"pt-2 pb-2"}>
                <div className="col-md-12 col-lg-12">
                  <fieldset>
                    {organisation.name && <span className="font-weight-bold">{organisation.name},&nbsp;</span>}
                    {address && <span className="font-weight-bold">{address},&nbsp;</span>}
                    {municipality && <span className="font-weight-bold">{municipality}&nbsp;</span>}
                    <br />
                    {organisation.phone_number && (
                      <span className="font-weight-normal">tel.: {organisation.phone_number},&nbsp;</span>
                    )}
                    {organisation.www && (
                      <span className="font-weight-normal">
                        www:{" "}
                        <a href={`${organisation.www}`} target={"_blank"}>
                          {organisation.www}
                        </a>
                      </span>
                    )}
                  </fieldset>
                </div>
                <div className={"col-lg-12 col-md-12 col-xs-6 pt-3"}>
                  {!registryVerified ? (
                    <Alert color={"danger"}>
                      <h4 className={"alert-heading"}>Obec {organisation.name} nemá ověřený e-mail podatelny.</h4>
                      <p>
                        Tento formulář nelze elektronicky podat. Formulář bude dostupný až poté, kdy obcí pověřená osoba
                        ověří e-mail podatelny.
                      </p>
                    </Alert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          } else {
            return <div>no header data</div>;
          }
        }}
      </Query>
    );
  }
}

export default Header;
