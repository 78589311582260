import React from "react";
import Dropzone from "react-dropzone";
import _ from "lodash";
import { toast } from "react-toastify";

export interface DropzoneProps {
  handleAddAcceptedAtachments: any;
  maxFiles: number;
}
export interface DropzoneState {
  acceptedFiles: any;
  attachmentError: boolean;
}

import styles from "./dropzone.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Label } from "reactstrap";

const humanFileSize = (size: number) => {
  const pow = 1000; //1024
  const i = Math.floor(Math.log(size) / Math.log(pow));
  const s = (size / Math.pow(pow, i)).toFixed(0);
  return s + " " + ["B", "kB", "MB", "GB", "TB"][i];
};

export default class DropzoneUpload extends React.Component<DropzoneProps, DropzoneState> {
  MAX_FILE_BYTE_SIZE = 20 * 1000 * 1000;

  constructor(props: DropzoneProps) {
    super(props);
    this.state = {
      acceptedFiles: [],
      attachmentError: false
    };
    this.handleAddAttachments = this.handleAddAttachments.bind(this);
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
  }

  private handleAddAttachments = (files: any, rejectedFiles: any) => {
    this.setState({ attachmentError: false });
    let tempState_init: any = [];
    let tempState_future: any = [];
    let result: any = [];
    if (!files.length) {
      return;
    }

    const rest = this.props.maxFiles - this.state.acceptedFiles.length;
    if (rest < files.length) {
      toast(`Maximální počet souborů je: ${this.props.maxFiles}`, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        position: "top-right",
        closeOnClick: true
      });
      if (rest < 1) {
        return;
      }
      files = files.slice(0, rest);
    }

    if (this.state.acceptedFiles && this.state.acceptedFiles.length) {
      try {
        _.map(this.state.acceptedFiles, (value: any, key: any) => {
          tempState_init[key] = value;
        });
        _.map(files, (file: any) => {
          let contains: any = [];

          _.map(this.state.acceptedFiles, (value: any) => {
            if (value.name === file.name && value.path === file.path) {
              contains.push(true);
            } else {
              contains.push(false);
            }
          });
          if (_.includes(contains, true)) {
            return null;
          } else {
            tempState_future.push(file);
          }
        });
        if (tempState_init && tempState_init.length) {
          _.map(tempState_init, (value: any) => {
            result.push(value);
          });
        }

        if (tempState_future && tempState_future.length) {
          _.map(tempState_future, (value: any) => {
            result.push(value);
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      _.map(files, (value: any, key: any) => {
        result[key] = value;
      });
    }

    this.setState({ acceptedFiles: result }, () => this.props.handleAddAcceptedAtachments(this.state.acceptedFiles));
  };

  handleRemoveAttachment = async (e: any, indexToRemove: any) => {
    e.preventDefault();
    let array = this.state.acceptedFiles;
    array = await array.filter((value: any, index: any, array: any) => index !== indexToRemove);
    await this.setState({ acceptedFiles: array });
    await this.props.handleAddAcceptedAtachments(this.state.acceptedFiles);
  };

  onCancel() {
    this.setState({
      acceptedFiles: this.state.acceptedFiles
    });
  }

  fileTooLarge = async (files, event) => {
    files.map(async file => {
      await toast(`Soubor ${file.name} nesmí být větší jak ${humanFileSize(this.MAX_FILE_BYTE_SIZE)}`, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        position: "top-right",
        closeOnClick: true
      });
    });
  };

  render() {
    const files = this.state.acceptedFiles.map((file: any, index: any) => (
      <Alert color={"dark"} key={index} data-id={index}>
        {file.name} - {humanFileSize(file.size)}{" "}
        <Button className={"close"} onClick={(e: any) => this.handleRemoveAttachment(e, index)}>
          <span aria-hidden={true}>&times;</span>
        </Button>
      </Alert>
    ));

    return (
      // <fieldset className={"mt-5"}>
      //   <legend>
      //     <FontAwesomeIcon icon={faFile} className={"mr-2"} /> Přílohy
      //   </legend>
      <div className="row justify-content-md-center ">
        <div className="col-lg-6">
          <Dropzone
            onDrop={this.handleAddAttachments}
            onDropRejected={this.fileTooLarge}
            onFileDialogCancel={this.onCancel.bind(this)}
            maxSize={this.MAX_FILE_BYTE_SIZE}
          >
            {({ getRootProps, getInputProps, isDragReject }) => (
              <div className={"dropzone"} {...getRootProps()}>
                <input name={"files"} className={"dropzone__file"} {...getInputProps()} />
                <p>Přetáhnout soubory z počítače nebo</p>
                <Label for={"files"} className={"dropzone__label btn btn-outline-secondary"}>
                  Vybrat soubory
                </Label>
                <div>max. {humanFileSize(this.MAX_FILE_BYTE_SIZE)}</div>
              </div>
            )}
          </Dropzone>
          <div className={styles.dropzoneAside}>{files}</div>
        </div>
      </div>
      // </fieldset>
    );
  }
}
