import React from "react";

// GraphQL query
import { Query } from "react-apollo";
import ORGANISATION_QUERY from "../../queries/organisation";

// Semantic UI
import Signed from "../Signed";
import DropzoneUpload from "../Dropzone";
import FooterForm from "./footerForm";
import Podavajici from "./Podavajici";
import Pouceni from "./Pouceni";

// Interfaces
import { AppProps, AppState, Data, Variables, LocalStorage } from "./types";

// Import Local storage
import StoreLocal from "../../libs/LocalStorage";

// Import regular stylesheet

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheck,
  faClipboardCheck,
  faExclamation,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Captcha from "../Captcha/Captcha";

library.add(faSpinner, faCheck, faExclamation);

class Footer extends React.Component<AppProps, AppState> {
  private error = true;
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private componentName = "form_footer";
  private date: string = new Date().toISOString().substring(0, 10);

  constructor(props: AppProps) {
    super(props);
    this.state = {
      signedModal: false,
      passwordVerified: false,
      data: {},
      mainValidatorError: true
    };
  }

  private handleReload = () => {
    window.location.reload();
  };

  private handleAddAcceptedAtachments = async (acceptedFiles: any) => {
    await this.props.sendAttachmentsToSubmit(acceptedFiles);
  };

  public render() {
    const id_organisations = this.props.id_organisations;
    return (
      <Query<Data, Variables> query={ORGANISATION_QUERY} variables={{ id_organisations }}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <FontAwesomeIcon icon={faSpinner} />
              </div>
            );
          if (error) return <div>Error loading tha data: {error.message}</div>;
          const _data: any = data;
          const organisation: any = _data.organisation;
          const registryVerified: any = organisation.registry_verified;
          return (
            <div className={"col pb-3 d-flex justify-content-center mb-3"}>
              <Captcha>
                <button className="btn btn-secondary" type="submit" disabled={this.props.submitted}>
                  {this.props.submitted ? "Odesílání..." : "Odeslat"}
                </button>
                {this.props.validatorError && (
                  <div className="text-danger" style={{ position: "absolute", bottom: -10 }}>
                    Musíte vyplnit všechna povinná pole
                  </div>
                )}
              </Captcha>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Footer;
