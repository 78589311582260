import React from "react";

// Import Local storage
import StoreLocal from "../../../libs/LocalStorage";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity, faUser, faUserTie } from "@fortawesome/free-solid-svg-icons";

// Interfaces
export interface LocalStorage {
  clearLocalStorage: any;
  saveToLocalStorage: any;
  getFromLocalStorage: any;
}
export interface AppProps {
  officeId: string;
  prototypeId: string;
  componentId: string;
  data: Object;
  updateData(offficeId: string, prototypeId: string, componentId: string, componentName: string, data: Object): void;
}
export interface AppState {
  informace_zadatel_name: string;
  informace_zadatel_surname: string;
  informace_zadatel_rc: string;
  informace_zadatel_adresa: string;
  informace_zadatel_dorucovaci_adresa: string;
}

class InformaceZadatel extends React.Component<AppProps, AppState> {
  private storeLocal: LocalStorage = new StoreLocal();
  /** ID for local storage */
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  private componentName = "form_informace_zadatel";

  constructor(props: AppProps) {
    super(props);
    this.state = {
      informace_zadatel_name: "",
      informace_zadatel_surname: "",
      informace_zadatel_rc: "",
      informace_zadatel_adresa: "",
      informace_zadatel_dorucovaci_adresa: ""
    };
    this.onChange = this.onChange.bind(this);
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.storeLocal.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  private onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    await this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    } as { [K in keyof AppState]: AppState[K] });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.storeLocal.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faUserTie} className={"mr-2"} /> Žadatel
        </legend>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_name" className="col-lg-3 col-form-label text-lg-right">
            Jméno
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_name"
                name={"informace_zadatel_name"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_zadatel_name}
                placeholder={"Jméno"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_surname" className="col-lg-3 col-form-label text-lg-right">
            Příjmení
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_surname"
                name={"informace_zadatel_surname"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_zadatel_surname}
                placeholder={"Příjmení"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_rc" className="col-lg-3 col-form-label text-lg-right">
            Rodné číslo / Datum narození
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_rc"
                name={"informace_zadatel_rc"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_zadatel_rc}
                placeholder={"Rodné číslo / Datum narození"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa místa trvalého pobytu poplatníka
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_adresa"
                name={"informace_zadatel_adresa"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_zadatel_adresa}
                placeholder={"Adresa místa trvalého pobytu poplatníka"}
              />
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="informace_zadatel_dorucovaci_adresa" className="col-lg-3 col-form-label text-lg-right">
            Doručovací adresa poplatníka
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="informace_zadatel_dorucovaci_adresa"
                name={"informace_zadatel_dorucovaci_adresa"}
                onChange={e => {
                  this.onChange(e);
                }}
                value={this.state.informace_zadatel_dorucovaci_adresa}
                placeholder={"Doručovací adresa poplatníka"}
              />
              <small>pokud se liší od adresy místa trvalého pobytu</small>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default InformaceZadatel;
