import React from "react";
import PsiProps from "./PsiProps";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDog } from "@fortawesome/free-solid-svg-icons";

class FormPes extends React.Component<PsiProps> {
  constructor(props: PsiProps) {
    super(props);
  }

  public render() {
    if (this.props.data.psi) {
      return (
        <fieldset className={"mt-5"}>
          <legend>
            <FontAwesomeIcon icon={faDog} className={"mr-2"} />{" "}
            {this.props.data.psi.length <= 1 ? "Popis psa" : "Popis psů"}
          </legend>
          {this.props.data.psi.map((pes, idx) => (
            <div className={"card mt-4"} key={idx}>
              <div className="card-header text-center text-white bg-primary">{idx + 1}. pes</div>
              <div className={"card-body"}>
                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="cislo_znamky" className="col-lg-3 col-form-label text-lg-right">
                    Číslo známky
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="cislo_znamky"
                        name={"cislo_znamky"}
                        value={pes.cislo_znamky}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="cislo_cipu" className="col-lg-3 col-form-label text-lg-right">
                    Číslo čipu
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="cislo_cipu"
                        name={"cislo_cipu"}
                        value={pes.cislo_cipu}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="plemeno" className="col-lg-3 col-form-label text-lg-right">
                    Plemeno
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="plemeno"
                        name={"plemeno"}
                        value={pes.plemeno}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="stari" className="col-lg-3 col-form-label text-lg-right">
                    Stáří
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="stari"
                        name={"stari"}
                        value={pes.stari}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="vyska" className="col-lg-3 col-form-label text-lg-right">
                    Výška v kohoutku
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="vyska"
                        name={"vyska"}
                        value={pes.vyska}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="barva" className="col-lg-3 col-form-label text-lg-right">
                    Barva srsti
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="barva"
                        name={"barva"}
                        value={pes.barva}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="pohlavi" className="col-lg-3 col-form-label text-lg-right">
                    Pohlaví
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="pohlavi"
                        name={"pohlavi"}
                        value={pes.pohlavi}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div className={"form-group align-items-center row mt-4"}>
                  <label htmlFor="drzen_od" className="col-lg-3 col-form-label text-lg-right">
                    Držen od
                  </label>
                  <div className="col-lg-6">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="drzen_od"
                        name={"drzen_od"}
                        value={pes.drzen_od}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </fieldset>
      );
    } else {
      return "";
    }
  }
}

export default FormPes;
