import React from "react";

// Import regular stylesheet

// Import Local storage
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed } from "@fortawesome/free-solid-svg-icons";

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
}

class FormKapacitaProvozovna extends React.Component<AppProps> {
  private componentName = "form_kapacita_provozovna";

  constructor(props: AppProps) {
    super(props);
  }

  public render() {
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faBed} className={"mr-2"} />
          Provozovna
        </legend>

        <div className={"form-group align-items-center row"}>
          <label htmlFor="kapacita_provozovna_adresa" className="col-lg-3 col-form-label text-lg-right">
            Adresa provozovny
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="text"
                className="form-control"
                id="kapacita_provozovna_adresa"
                name={"kapacita_provozovna_adresa"}
                value={this.props.data.kapacita_provozovna_adresa}
                readOnly={true}
              />
              <div className="invalid-feedback">Vyplňte adresu provozovny</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row"}>
          <label htmlFor="kapacita_provozovna_kapacita" className="col-lg-3 col-form-label text-lg-right">
            Kapacita - počet lůžek
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="kapacita_provozovna_kapacita"
                name={"kapacita_provozovna_kapacita"}
                value={this.props.data.kapacita_provozovna_kapacita}
                readOnly={true}
              />
              <div className="invalid-feedback">Vyplňte kapacitu</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row"}>
          <label htmlFor="kapacita_provozovna_luzka" className="col-lg-3 col-form-label text-lg-right">
            Počet využitých lůžek za příslušné čtvrtletí
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="kapacita_provozovna_luzka"
                name={"kapacita_provozovna_luzka"}
                value={this.props.data.kapacita_provozovna_luzka}
                readOnly={true}
              />
              <div className="invalid-feedback">Vyplňte počet využitých lůžek</div>
            </div>
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <div className={"col-lg-12 col-form-label text-center"}>
            Sazba poplatku z ubytovací kapacity činí za každé využité lůžko a den 2,- Kč.
          </div>
        </div>

        <div className={"form-group align-items-center row mt-4"}>
          <label htmlFor="kapacita_provozovna_poplatek" className="col-lg-3 col-form-label text-lg-right">
            Celková výše poplatku
          </label>
          <div className="col-lg-6">
            <div className="form-field-wrap">
              <Input
                type="number"
                className="form-control"
                id="kapacita_provozovna_poplatek"
                name={"kapacita_provozovna_poplatek"}
                value={this.props.data.kapacita_provozovna_poplatek}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default FormKapacitaProvozovna;
