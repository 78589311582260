import React from "react";
import { AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

interface RadioProps {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
  value?: string;
  readOnly?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
}

interface RadioState {
  value: string | null;
}

export default class Radio extends React.Component<RadioProps, RadioState> {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || null
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ value: event.target.value });

    this.props.onChange && this.props.onChange(event.target.value);
  }

  render(): React.ReactNode {
    const { options, name, required } = this.props;

    return (
      <AvRadioGroup
        value={this.state.value}
        className={"customeradio"}
        name={name}
        required={required}
        errorMessage="Prosím vyberte kategorii!"
      >
        {options.map((option, index) => (
          <AvRadio
            key={option.value}
            label={option.label}
            value={option.value}
            // className="custom-control-input"
            onChange={this.handleChange}
            id={`${name}_${index}`}
            disabled={Boolean(this.props.readOnly)}
          />
        ))}
      </AvRadioGroup>
    );
  }
}
