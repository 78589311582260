import gql from "graphql-tag";

const ORGANISATION_LINK_QUERY = gql`
  query OrganisationLinkQuery($organisation_slug: String!) {
    orgforms(slug: $organisation_slug) {
      id_organisations
      name
      slug
      street_name
      house_number
      city
      zip_code
      registry_email
      registry_verified
      bought_forms {
        id_bought_forms
        is_public
        form {
          id_forms
          name
          slug
        }
      }
    }
  }
`;

export default ORGANISATION_LINK_QUERY;
