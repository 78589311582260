import React from "react";

const Pdf = () => (
    <div style={{ textAlign: "left", fontWeight: 800 }}>
        <h2>
            Obecné podání - podatelna
        </h2>
    </div>
);

export default Pdf;