import React, { Suspense } from "react";

// GraphQL query
import { Query } from "react-apollo";
import SENT_FORM_QUERY from "../../queries/sentForm";

import styled from "styled-components";

// Form Components
import HeaderPdf from "../Results/form_header/pdf";
import FooterPdf from "../Results/form_footer/pdf";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import jws from "jws";

library.add(faSpinner);

// Type interfaces
import ComponentVariables, { AppProps, AppState, Data, Variables } from "./types";
import COMPONENT_QUERY from "../../queries/component";

const PdfContainer = styled.div`
  @media print {
    html,
    body {
      width: 210mm;
      height: 297mm;
    }
  }
`;

class RenderPdf extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      dataPrototype: [],
      signedModal: false,
      unSignedModal: false,
      attachments: [],
      privateKey: "",
      publicKey: "",
      submittingSignedForm: false,
      formResolved: false,
      formDeployed: false
    };
  }

  componentDidMount() {
    import("./pdfs.css");
  }

  private getData(data: Object) {
    let header: Object = {};
    Object.keys(data).forEach(key => {
      header = data[`${key}`];
    });
    return header;
  }

  public render() {
    const id_sent_forms: string = this.props.match.params.id;
    return (
      <Query<Data, Variables> query={SENT_FORM_QUERY} variables={{ id_sent_forms }}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <FontAwesomeIcon icon={faSpinner} />
              </div>
            );
          if (error) return <div>Error loading tha data: {error.message}</div>;
          const _data: any = data;
          if (_data.sent_forms) {
            const sent_forms: any = _data.sent_forms;
            const formData = sent_forms.data;
            const issuer = _data.sent_forms.certificate_issuer;
            const pubkey = sent_forms.pubkey;
            let decode: any = {};
            let form: any = {};

            if (pubkey) {
            }

            if (pubkey) {
              const verify = jws.verify(formData, "RS256", pubkey);
              if (verify) {
                decode = jws.decode(formData);
              }
              form = JSON.parse(decode.payload);
            } else {
              form = formData;
            }

            const components = _data.sent_forms.form.components.sort(function(a, b) {
              return parseInt(a.position) - parseInt(b.position);
            });

            const componentsData = form.components;

            const header = form.header;
            const footer = this.getData(form.footer);
            const files = sent_forms.attachments || {};

            return (
              <PdfContainer>
                <HeaderPdf data={header} issuer={issuer} formData={sent_forms} />
                {components.map((key: any, index: any) => {
                  const Component: any = React.lazy(() => import(`../Results/${key.slug}/pdf`));
                  const id: string = key.id;
                  return (
                    <Query<Data, ComponentVariables> query={COMPONENT_QUERY} variables={{ id }} key={id}>
                      {({ loading, error, data }) => {
                        const _data: any = data;
                        if (loading)
                          return (
                            <div>
                              <FontAwesomeIcon icon={faSpinner} />
                            </div>
                          );
                        if (error) return <div>Error loading tha data: {error.message}</div>;
                        return (
                          <Suspense key={`sx_${index}`} fallback={<div>Loading...</div>}>
                            <Component
                              key={`cx_${index}`}
                              name={_data.component.name}
                              data={componentsData[key.slug]}
                            />
                          </Suspense>
                        );
                      }}
                    </Query>
                  );
                })}
                <FooterPdf footer={footer} files={files} />
              </PdfContainer>
            );
          } else {
            return <div>Result not found</div>;
          }
        }}
      </Query>
    );
  }
}

export default RenderPdf;
