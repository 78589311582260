import React from "react";
import { Link, Redirect } from "react-router-dom";
import "../../styles/app.css";

// GraphQL query
import { Query } from "react-apollo";
import ORGANISATION_LINK_QUERY from "../../queries/organisationLink";

// Import regular stylesheet

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faClipboardCheck,
  faClipboardList,
  faExclamationCircle,
  faInfo,
  faInfoCircle,
  faList,
  faListAlt,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { Grid } from "semantic-ui-react";
import { render } from "react-dom";
import { Alert, Button } from "reactstrap";

library.add(faClipboardList);

// Interfaces
export interface AppProps {
  data: any;
  name?: string;
  match: any;
  history: any;
  location: any;
}

export interface Data {
  organisations: {
    id_organisations: string;
    name: string;
    slug: string;
    street_name: string;
    house_number: string;
    city: string;
    zip_code: string;
    phone_number: string;
    contact_email: string;
    registry_email: string;
    registry_verified: boolean;
    created: string;
    modified: string;
    bought_forms: {
      id_bought_forms: string;
      id_forms: string;
      id_organisations: string;
      is_public: string;
      created: string;
      modified: string;
    };
  };
}

export interface Variables {
  organisation_slug: string;
}

class Office extends React.Component<AppProps, any> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      publicFormCounter: 0
    };
  }

  renderFormsLinks = (organisation: any) => {
    if (organisation.bought_forms.some(k => k.is_public === true)) {
      return (
        <div className={"w-100"}>
          <div className={"row justify-content-center text-center mt-4"}>
            <div className={"col-12"}>
              <h2>
                {organisation.name}, {organisation.street_name} {organisation.house_number}, {organisation.city}{" "}
                {organisation.zip_code}
              </h2>
            </div>
            <div className={"row justify-content-center col-12 mt-1"}>
              <div className={"col-2 border-bottom border-eforms border-bottom-width-3"}></div>
            </div>
            <div className={"col-12 mt-2"}>
              <h6 className={"font-weight-light"}>
                Elektronický formulář k ohlášení incidentu podle zákona na ochranu oznamovatelů.
              </h6>
            </div>
            <div className={"col-12"}>
              <h6 className={organisation.registry_verified ? "font-weight-light" : "font-weight-normal text-danger"}>
                {organisation.registry_verified
                  ? `Formulář bude odeslán na adresu příslušné organizace  ${
                      organisation.registry_email ? organisation.registry_email : ""
                    }.`
                  : `Organizace nemá ověřený e-mail podatelny, formuláře není možné elektronicky podat.`}
              </h6>
            </div>
          </div>
          <div className={"mt-5 mb-5 text-center"}>
            <p>Zde můžete ohlásit incident.</p>

            <div className={""}>
              <Link
                className={"text-body form-link text-decoration-none"}
                to={`/${organisation.slug}/zalozeni_noveho_incidentu`}
              >
                <div className="btn mx-4 btn-eforms-sec rounded-pill">
                  <FontAwesomeIcon className="mr-3" icon={faListAlt} />
                  Ohlásit incident
                </div>
              </Link>

              {/*<Link*/}
              {/*  className={'text-body form-link text-decoration-none'}*/}
              {/*  to={`/${organisation.slug}/todo`}*/}
              {/*>*/}
              {/*  <div className="btn mx-4  btn-eforms rounded-pill">*/}
              {/*    <FontAwesomeIcon className="mr-3" icon={faInfo} />*/}
              {/*    Stav incidentu*/}
              {/*  </div>*/}
              {/*</Link>*/}
            </div>
            {/*{organisation.bought_forms.map((bought_form: any, key: any) => {*/}
            {/*  if (bought_form.is_public) {*/}
            {/*    return (*/}
            {/*      <div className="col-xl-4 col-md-6">*/}
            {/*        <div className="card d-flex border-0 h-100 py-2">*/}
            {/*          <Link*/}
            {/*            className={"text-body form-link text-decoration-none"}*/}
            {/*            to={`/${organisation.slug}/${bought_form.form.slug}`}*/}
            {/*          >*/}
            {/*            <div className="card-body align-items-center d-block">*/}
            {/*              <div className="row no-gutters align-items-center">*/}
            {/*                <div className="btn btn-eforms rounded-circle">*/}
            {/*                  <FontAwesomeIcon icon={faList} />*/}
            {/*                </div>*/}
            {/*                <div className="col-7 col-xl-8 col-md-6 col-sm-8 ml-2">*/}
            {/*                  <div key={key} className="text-xs mb-1">*/}
            {/*                    {bought_form.form.name}*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </Link>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    );*/}
            {/*  }*/}
            {/*})}*/}
          </div>
          <div className={"row justify-content-center text-center border-top pt-5"}>
            <div className={"col-12"}>
              <h2>NÁVOD K POUŽITÍ</h2>
            </div>
            <div className={"row justify-content-center col-12 mt-1"}>
              <div className={"col-2 border-bottom border-eforms border-bottom-width-3"}></div>
            </div>
          </div>
          <div className="row d-block align-items-stretch">
            <div className="card-group">
              <div className="col-sm-12">
                <div className="card border-0">
                  <div className="card-body">
                    {/*<h2 className="faq-heading">01.</h2>*/}
                    <h5 className="card-title text-uppercase">Vyplňte formulář qHlas</h5>
                    <p className="card-text font-weight-light text-muted">
                      Ve formuláři vyplňte všechny povinné údaje (označené *). Popište incident jasně a věcně, bez
                      použití vulgarismů. <strong>V případě, že oznamovatel nevyplní údaje o jménu, příjmení a datu narození,
                      nebo jiné údaje, z nichž je možné odvodit jeho totožnost, se na něj nebude vztahovat ochrana dle
                      zákona na ochranu oznamovatelů.</strong> Když máte k incidentu relevantní důkaz, můžete ho přiložit do
                      položky <strong>Přílohy k incidentu</strong>, prostřednictvím tlačítka <strong>Vybrat soubory</strong>. Při uvedení vaší emailové
                      adresy budete přímo na váš email upozorněn při změně stavu vašeho incidentu.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card border-0">
                  <div className="card-body">
                    {/*<h2 className="faq-heading">02.</h2>*/}
                    <h5 className="card-title text-uppercase">Odeslání formuláře</h5>
                    <p className="card-text font-weight-light text-muted">
                      Po odeslání formuláře qHlas vám bude zobrazen odkaz, na základě kterého můžete sledovat stav
                      podaného incidentu. Odkaz si uložte a nikde nezveřejňujte. Když zadáte emailovou adresu, odkaz vám
                      bude zaslán i na uvedený email.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card border-0">
                  <div className="card-body">
                    {/*<h2 className="faq-heading">03.</h2>*/}
                    <h5 className="card-title text-uppercase">Sledování stavu incidentu</h5>
                    <p className="card-text font-weight-light text-muted">
                      Když budete chtít zkontrolovat stav podaného incidentu, použijete odkaz, který se vám zobrazil po
                      odeslání incidentu. Když jste zadali při ohlášení incidentu vaší emailovou adresu, tak vám bude
                      při změně stavu incidentu doručena notifikace. Změna stavu bude viditelná v pravém horním rohu.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="card border-0">
                  <div className="card-body">
                    {/*<h2 className="faq-heading">04.</h2>*/}
                    <h5 className="card-title text-uppercase">Vyřešení incidentu</h5>
                    <p className="card-text font-weight-light text-muted">
                      Organizace má na vyřešení incidentu podle zákona na ochranu oznamovatelů 30 dnů. Při vážnosti
                      incidentu se tato lhůta může prodloužit. K výsledku vámi podaného incidentu se dostanete znovu
                      přes odkaz, který vám byl zobrazen po odeslání incidentu nebo zaslán na vámi uvedenou emailovou
                      adresu. Výsledek vyřešeného incidentu bude viditelný dole pod formulářem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Alert color={"info"} className={"row justify-content-center align-items-center col-12 mt-5 text-center"}>
        <FontAwesomeIcon size={"4x"} icon={faInfoCircle} className={"mr-2"} /> Organizace {organisation.name} nemá
        momentálně k dispozici žádný elektronický formulář.
      </Alert>
    );
  };

  render() {
    const organisation_slug = this.props.match.params.organisation;

    return (
      <Query<Data, Variables>
        query={ORGANISATION_LINK_QUERY}
        fetchPolicy={"network-only"}
        variables={{ organisation_slug }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <FontAwesomeIcon icon={faSpinner} />
              </div>
            );
          if (error) return <div>Chyba při nahrávání dat: {error.message}</div>;
          const _data: any = data;
          const organisation: any = _data.orgforms;
          if (organisation) {
            return <div className={"row"}>{this.renderFormsLinks(organisation)}</div>;
          }
          return (
            <Alert color={"info"} className={"row justify-content-center align-items-center col-12 mt-5 text-center"}>
              <FontAwesomeIcon size={"4x"} icon={faInfoCircle} className={"mr-2"} /> Daná organizace neexistuje.
            </Alert>
          );
        }}
      </Query>
    );
  }
}

export default Office;
