import React from "react";
import PsiProps from "./PsiProps";
import { PsiState } from "./PsiState";
import { LocalStorage } from "../../../interfaces/LocalStorage";
import StoreLocal from "../../../libs/LocalStorage";
import { Button, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDog } from "@fortawesome/free-solid-svg-icons";

class FormPes extends React.Component<PsiProps, PsiState> {
  private componentName = "form_psi_pes";
  private localStorage: LocalStorage = new StoreLocal();
  private id: string = this.props.officeId.concat(this.props.prototypeId, this.props.componentId);
  constructor(props: PsiProps) {
    super(props);
    this.state = {
      psi: [
        {
          cislo_znamky: "",
          cislo_cipu: "",
          plemeno: "",
          stari: "",
          vyska: 0,
          barva: "",
          pohlavi: "",
          drzen_od: ""
        }
      ]
    };
  }

  public componentDidMount = async () => {
    const data = await JSON.parse(this.localStorage.getFromLocalStorage(this.id));
    if (data) {
      await this.setState(data);
    }
    this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      data
    );
  };

  handleAddDog = async () => {
    this.setState({
      psi: this.state.psi.concat([
        {
          cislo_znamky: "",
          cislo_cipu: "",
          plemeno: "",
          stari: "",
          vyska: 0,
          barva: "",
          pohlavi: "",
          drzen_od: ""
        }
      ])
    });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  handleRemoveDog = idx => async () => {
    this.setState({
      psi: this.state.psi.filter((s, sidx) => idx !== sidx)
    });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  handleDogDescChange = idx => async evt => {
    const newDogs = this.state.psi.map((dog, sidx) => {
      if (idx !== sidx) return dog;
      return { ...dog, [evt.currentTarget.name]: evt.currentTarget.value };
    });
    this.setState({ psi: newDogs });

    await this.props.updateData(
      this.props.officeId,
      this.props.prototypeId,
      this.props.componentId,
      this.componentName,
      this.state
    );

    /** Store component to the cache, according to component ID */
    await this.localStorage.saveToLocalStorage(`${this.id}`, `${JSON.stringify(this.state)}`);
  };

  public render() {
    // Clear the storage after form deployed
    return (
      <fieldset className={"mt-5"}>
        <legend>
          <FontAwesomeIcon icon={faDog} className={"mr-2"} /> {this.state.psi.length <= 1 ? "Popis psa" : "Popis psů"}
        </legend>
        {this.state.psi.map((pes, idx) => (
          <div className={"card mt-4"} key={idx}>
            <div className="card-header text-center text-white bg-primary">{idx + 1}. pes</div>
            <div className={"card-body"}>
              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="cislo_znamky" className="col-lg-3 col-form-label text-lg-right">
                  Číslo známky
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="cislo_znamky"
                      name={"cislo_znamky"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.cislo_znamky}
                      placeholder={"Číslo známky"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="cislo_cipu" className="col-lg-3 col-form-label text-lg-right">
                  Číslo čipu
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="cislo_cipu"
                      name={"cislo_cipu"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.cislo_cipu}
                      placeholder={"Číslo čipu"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="plemeno" className="col-lg-3 col-form-label text-lg-right">
                  Plemeno
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="plemeno"
                      name={"plemeno"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.plemeno}
                      placeholder={"Plemeno"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="stari" className="col-lg-3 col-form-label text-lg-right">
                  Stáří
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="stari"
                      name={"stari"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.stari}
                      placeholder={"Stáří"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="vyska" className="col-lg-3 col-form-label text-lg-right">
                  Výška v kohoutku
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="vyska"
                      name={"vyska"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.vyska}
                      placeholder={"Výška v kohoutku"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="barva" className="col-lg-3 col-form-label text-lg-right">
                  Barva srsti
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="barva"
                      name={"barva"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.barva}
                      placeholder={"Barva srsti"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="pohlavi" className="col-lg-3 col-form-label text-lg-right">
                  Pohlaví
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="pohlavi"
                      name={"pohlavi"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.pohlavi}
                      placeholder={"Pohlaví"}
                    />
                  </div>
                </div>
              </div>

              <div className={"form-group align-items-center row mt-4"}>
                <label htmlFor="drzen_od" className="col-lg-3 col-form-label text-lg-right">
                  Držen od
                </label>
                <div className="col-lg-6">
                  <div className="form-field-wrap">
                    <Input
                      type="text"
                      className="form-control"
                      id="drzen_od"
                      name={"drzen_od"}
                      onChange={this.handleDogDescChange(idx)}
                      value={pes.drzen_od}
                      placeholder={"Držen od"}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <Button type="button" onClick={this.handleRemoveDog(idx)} className="btn btn-sm btn-outline-primary">
                  Odebrat psa
                </Button>
              </div>
            </div>
          </div>
        ))}

        <div className="row d-flex justify-content-center mt-4">
          <Button type="button" onClick={this.handleAddDog} className="btn btn-sm btn-outline-primary">
            Přidat psa
          </Button>
        </div>
      </fieldset>
    );
  }
}

export default FormPes;
