import React, { ChangeEvent } from 'react';
import { Input } from "reactstrap";
import InputTextarea from "../../InputTextarea";
import Radio from "../../FormComponents/Radio";
import _ from "lodash";
import config from "../../../../config/config";
import { format } from "date-fns";
import { INCIDENT_KATEGORIE, INCIDENT_KATEGORIE_TYP, INCIDENT_VZTAH } from '../../Forms/form_zalozeni_noveho_incidentu';
import { AvForm } from "availity-reactstrap-validation";

export default class ZalozeniNovehoIncidentu extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  public renderFiles = (files: any) => {
    if (files) {
      return _.map(files, (file: any, key: any) => {
        return (
          <div key={key}>
            <a href={`${config.API}/${file.file}`}>{file.file_name}</a>
          </div>
        );
      });
    }
  };

  render() {
    const { data, created, fullData } = this.props;

    const odpoved = fullData.reseni;
    const stav = fullData.stav || "Nový";

    return (
      <>
        <div className="mt-4 d-flex justify-content-center">
          <h1 className="ml-1">Ohlášený incident</h1>
        </div>
        <fieldset className={"mt-5"}>
          <div className="d-flex justify-content-end">
            Stav: <b className="pl-1">{stav}</b>
          </div>
          <div className={"form-group align-items-center row"}>
            <label htmlFor="nazev" className="col-lg-12 col-form-label" style={{ fontSize: "1.1rem" }}>
              Název incidentu
            </label>
            <div className="col-lg-12">
              <div className="form-field-wrap">
                <Input
                  type="text"
                  className="form-control"
                  id="nazev"
                  name={"nazev"}
                  value={data && data.nazev}
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <fieldset>
                <legend>Incident nahlásil v {format(new Date(parseInt(created)), "dd.MM.yyyy HH:mm")}</legend>
                {/*<div className={"form-group align-items-center row"}>*/}
                {/*  <label htmlFor="email" className="col-lg-12 col-form-label">*/}
                {/*    Kontaktní e-mail*/}
                {/*  </label>*/}
                {/*  <div className="col-lg-12">*/}
                {/*    <div className="form-field-wrap">*/}
                {/*      <Input*/}
                {/*        type="text"*/}
                {/*        className="form-control"*/}
                {/*        id="email"*/}
                {/*        name={"email"}*/}
                {/*        value={data && data.kontaktniOsoba.email}*/}
                {/*        readOnly*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row">
                  <div className="col-lg-6">
                <div className={"form-group align-items-center row"}>
                  <label htmlFor="jmeno" className="col-lg-12 col-form-label">
                    Jméno
                  </label>
                  <div className="col-lg-12">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="jmeno"
                        name={"jmeno"}
                        value={data && data.kontaktniOsoba.jmeno}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className={"form-group align-items-center row"}>
                  <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                    Příjmení
                  </label>
                  <div className="col-lg-12">
                    <div className="form-field-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        id="prijmeni"
                        name={"prijmeni"}
                        value={data && data.kontaktniOsoba.prijmeni}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className={"form-group align-items-center row"}>
                  <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                    Datum narození
                  </label>
                  <div className="col-lg-12">
                    <div className="form-field-wrap">
                      <input className="form-control" type="date" data-date="" data-date-format="DD MMMM YYYY"
                             id="datumNarozeni"
                             name={"datumNarozeni"}
                             value={data && data.kontaktniOsoba.datumNarozeni}
                             readOnly
                      />
                    </div>
                  </div>
                </div>
                    <div className={'form-group align-items-center row'}>
                      <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                        Kontaktní adresa
                      </label>
                      <div className="col-lg-12">
                        <div className="form-field-wrap">
                          <Input
                            type="text"
                            className="form-control"
                            id="adresa"
                            name={'adresa'}
                            value={data && data.kontaktniOsoba.adresa}
                          />
                        </div>
                      </div>
                  </div>
                </div>

                    <div className="col-lg-6">
                <div className={"form-group align-items-center row"}>
                  <label htmlFor="prijmeni" className="col-lg-12 col-form-label">
                    Vztah oznamovatele k povinnému subjektu
                  </label>
                  <div className="col-lg-12">
                    <div className="form-field-wrap">
                      <AvForm>
                        <Radio
                          name="vztah"
                          options={INCIDENT_VZTAH}
                          value={data && data.kontaktniOsoba.vztah}
                          readOnly
                        />
                      </AvForm>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </fieldset>
            </div>
            <div className="col-lg-12 mt-5">
              <fieldset>
                <legend>Kategorie</legend>
                <div className="row">
                  <div className="col-lg-6">
                    <AvForm>
                      <Radio name="kategorieTyp" options={INCIDENT_KATEGORIE_TYP} value={data && data.kategorieTyp}
                             readOnly />
                    </AvForm>
                  </div>
                  <div className="col-lg-6">
                    <AvForm>
                      <Radio name="kategorie" options={INCIDENT_KATEGORIE} value={data && data.kategorie} readOnly />
                    </AvForm>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="col-lg-12">
              <fieldset>
                <legend>Popis incidentu</legend>

                <InputTextarea
                  id="popisIncidentu"
                  name="popisIncidentu"
                  rows={10}
                  value={data && data.popisIncidentu}
                  readOnly
                />
              </fieldset>
            </div>

            <div className="col-lg-12">
              <fieldset>
                <legend>Přílohy k incidentu</legend>
                {this.renderFiles(this.props.attachments)}
              </fieldset>
            </div>
          </div>
        </fieldset>
        {odpoved && (
          <div className="mt-5 col-lg-12">
            <fieldset>
              <legend>Řešení incidentu</legend>

              <InputTextarea id="odpoved" name="odpoved" rows={10} value={odpoved} readOnly />
            </fieldset>
          </div>
        )}
      </>
    );
  }
}
